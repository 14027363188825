<template>
    <div class="modal-template">
        <transition name="modal">
            <div
                class="modal-mask"
                :class="[show ? 'active' : '']"
                @click="closeModal($event)"
            >
                <div class="modal-wrapper">
                    <div
                        class="modal-container"
                        :style="'width:' + size + 'px'"
                        @click.stop
                    >
                        <div class="modal-header">
                            <slot name="header" />
                        </div>
                        <div class="modal-body">
                            <slot name="body" />
                        </div>
                        <div class="modal-footer">
                            <slot name="footer" />
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'BaseModal',
    components: {},
    props: {
        show: {
            default: false,
            type: Boolean,
        },
        size: {
            default: 380,
            type: Number,
        },
    },
    data() {
        return {}
    },
    methods: {
        closeModal: function(event) {
            let element = event.currentTarget
            let noclose = element.getAttribute('noclosemodal')
            if (noclose == 'false') {
                this.$emit('close')
            }
        },
    },
}
</script>

<style lang="less">
.modal-mask {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    &:-webkit-scrollbar {
        width: 0px; /* remove scrollbar space */
        background: transparent; /* optional: just make scrollbar invisible */
    }
    .modal-wrapper {
        display: flex;
        margin: auto;
        .modal-container {
            height: 0px;
            margin: 0 auto 30px auto;
            padding: 24px 24px;
            background-color: #ffffff;
            border-radius: 8px;
            border: 1px solid transparent;
            box-shadow: 0 2px 8px rgba(35, 35, 35, 0.33);
            transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            position: relative;
            .modal-close-button {
                position: absolute;
                right: 1rem;
                top: 1rem;
                z-index: 9998;
                border-color: transparent;
                cursor: pointer;
                background-color: #f9f9f9;
                svg {
                    width: 0.8rem;
                }
            }
            .modal-header {
                margin-bottom: 24px;
                .header-title {
                    font-family: 'AvenirBL';
                }
            }
            .modal-body {
                .content-body--delete {
                    .desc {
                        font-family: 'HelveticaR';
                        margin-bottom: 1rem;
                        .title {
                            font-family: 'HelveticaM';
                        }
                    }
                }
            }
            // .modal-footer{

            // }
        }
    }
}
.modal-mask.active {
    transition: opacity 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    opacity: 1;
    display: flex;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 9998;
    .modal-wrapper {
        margin: auto;
        .modal-container {
            height: 100%;
        }
    }
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
</style>
