<template>
    <div class="container mx-auto py-10">
        <div class="block bg-blue-100 flex flex-wrap p-10">
            <!-- sisi hubungi kami -->
            <div class="text-left flex-none lg:w-1/2 md:w-full px-5">
                <h1
                    class="font-bold text-3xl mb-2"
                    :style="{ color: '#0D528D' }"
                >
                    Hubungi Kami 📬
                </h1>

                <p class="pb-3">
                    Kamu mempunyai pertanyaan atau pesan untuk GenBI IPB?
                    silahkan isi pesanmu pada form di bawah ini dan kami akan
                    membalas pertanyaanmu secepatnya.
                </p>

                <!-- input nama and email -->
                <form @submit.prevent="postForm">
                    <div class="flex">
                        <div class="pr-5">
                            <p class="font-bold pb-1">Nama Kamu</p>
                            <input
                                name="from_name"
                                v-model="contact.name"
                                type="text"
                                placeholder="Your Name"
                                class="rounded-full p-2"
                            />
                        </div>

                        <div class="pl-5">
                            <p class="font-bold pb-1">Email Kamu</p>
                            <input
                                name="from_email"
                                v-model="contact.email"
                                type="email"
                                placeholder="example@mail.com"
                                class="rounded-full p-2"
                            />
                        </div>
                    </div>

                    <div class="pr-5 py-3">
                        <p class="font-bold pb-1">Pesanmu</p>
                        <!-- <input
                        name=""
                            v-model="contact.message"
                            type="text"
                            placeholder="Your Name"
                            class="rounded-lg p-2 w-full h-full"
                        /> -->
                        <textarea
                            placeholder="Pesanmu"
                            v-model="contact.message"
                            class="rounded-lg p-2 w-full h-full"
                            name="message"
                        />
                    </div>

                    <div class="pt-3 pb-5">
                        <!-- <button
                            @click="postForm"
                            class="rounded-full px-6 py-2 bg-blue-500 text-white"
                        >
                            Kirim Pesan
                        </button> -->
                        <input
                            type="submit"
                            value="Send"
                            style="cursor:pointer"
                            class="rounded-full px-6 py-2 bg-blue-500 text-white"
                        />
                    </div>
                </form>
            </div>

            <!-- sisi peta -->
            <div class="text-left flex-grow px-5 lg:w-1/2 md:w-full">
                <!--  peta -->
                <div
                    style="
                        overflow: hidden;
                        max-width: 100%;
                        width: 398px;
                        height: 240px;
                    "
                    class="rounded-lg"
                >
                    <div
                        id="mymap-display"
                        style="height: 100%; width: 100%; max-width: 100%"
                    >
                        <iframe
                            style="height: 100%; width: 100%; border: 0"
                            frameborder="0"
                            src="https://www.google.com/maps/embed/v1/place?q=Institut+pertanian+Bogor&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                        ></iframe>
                    </div>
                    <a
                        class="embed-maphtml"
                        href="https://www.embed-map.com"
                        id="get-mapdata"
                        >https://www.embed-map.com</a
                    >
                </div>

                <div class="flex pt-5 w-3/4">
                    <img
                        src="https://lh3.googleusercontent.com/rxOXv2S1NT4G3kKJtW1JTKwJ3LkV4xig5guAMX2arNgBdPQdw_9-aMHgdni-u8M6ahn4UJkAXaJ-qzQaEhWDEBFn8krgg2XVSOnemVRTP4KJsvpCXO5zq2aghoaL44MMTJvF9gR0qA=s72-p-k"
                        alt=""
                        class="h-5 w-5"
                    />
                    <p class="pl-2 mb-2">
                        Kampus IPB, Jl. Raya Dramaga, Babakan, Kec. Dramaga,
                        Kota Bogor, Jawa Barat 16680
                    </p>
                </div>

                <div class="flex pt-5 w-3/4">
                    <img
                        src="https://lh3.googleusercontent.com/lVXovdseKNFGbsNX38ZPJJnQpsjHqgSGW4cRN4nVwjKXNNx1FOEhOuMp0bM3mh_FY-vvxOc87W_OApzHvG9YQJjb4_YjyjhTo2mT0xdas0yNSQqG3g9D_MVinxzILzDjE0WtTAbCqA=s72-p-k"
                        alt=""
                        class="h-5 w-5"
                    />
                    <p class="pl-2 mb-2">genbiipb@gmail.com</p>
                </div>

                <div class="grid lg:grid-cols-3 md:grid-cols-1 gap-10 py-1">
                    <div class="grid grid-cols-3 gap-x-0 pt-3">
                        <a href="https://www.linkedin.com/company/genbi-ipb/"
                            ><img
                                src="https://lh3.googleusercontent.com/ibnGwZVMUlezjwJMB4ELx2w5WorEEIPZJm2hoEJ9_ztyuvQtA76TxxJ6OCb_qVzBEdSEunNcz5X0SqIDd-9_4x9Usnapk0Z7IZyt-R63QnsGV1EsLBfLMaa3xVzEDUTO-81Jlt2oug=s112-p-k"
                                alt="Instagram"
                                class="h-10 w-10"
                        /></a>
                        <a href="https://www.instagram.com/genbi_ipb/"
                            ><img
                                src="https://lh3.googleusercontent.com/wt6yndwuUlfzWbKUoOgxoXlxfJQ_XdS6H2DaUXeKnAsRRsCIrIWRH_Srbx7Tl8OGKTv5pPJBNHdOtLDPbCKeFyW-TNtapSv0W96_nxzAmRITVQaCUqC09vgqS4hZL9Yh5Eox3rvcMg=s112-p-k"
                                alt="LinkedIn"
                                class="h-10 w-10"
                        /></a>
                        <a
                            href="https://www.youtube.com/channel/UCBaW7cQ9Fw-TOr4tpazWtJQ"
                            ><img
                                src="https://lh3.googleusercontent.com/oL7kWQh59QtLwaVLyHBxGUnb4rRZd9lJ3Hql4AMqKOpj3X_2ocTSBFS-ux0Bdfkc9dc96WI8tYWiqsDhNtv8WCkqlZOwGt9UJq6VJCVBrgmkXe157rBUQ-r1Ot0-5OzytmuzfDTBGQ=s112-p-k"
                                alt="Youtube"
                                class="h-10 w-10"
                        /></a>
                    </div>
                </div>
            </div>
        </div>
        <modal :show="modal.show">
            <div slot="header">
                <h5>Sedang mengirim Pesan</h5>
            </div>
            <div slot="body">
                <p>
                    Mohon menunggu ....
                </p>
            </div>
        </modal>
    </div>
</template>

<script>
import emailjs from 'emailjs-com'
import Modal from '@/components/Modal'
export default {
    components: {
        Modal,
    },
    // methods: {
    //     postForm() {
    //         axios
    //             .post(
    //                 `${feedback_api}?tabId=Sheet1&api_key=YdVGnggDdmEeqwGlC`,
    //                 [[1, 2, 3]],
    //                 {
    //                     headers: {
    //                         Authorization: 'API Key',
    //                     },
    //                 }
    //             )
    //             .then(() => {
    //                 this.$fire({
    //                     title: 'Berhasil!',
    //                     text: 'Pesan berhasil dikirim',
    //                     type: 'success',
    //                     timer: 3000,
    //                 }).then(r => {
    //                     console.log(r.value)
    //                 })
    //             })
    //     },
    // },
    data() {
        return {
            contact: {
                name: '',
                email: '',
                message: '',
            },
            modal: {
                show: false,
            },
        }
    },
    metaInfo: {
        titleTemplate: `%s - Kontak`,
        htmlAttrs: {
            lang: 'id',
            amp: true,
        },
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    methods: {
        async postForm(e) {
            console.log(this.contact)
            this.modal.show = true
            try {
                await emailjs.sendForm(
                    'service_rmaropl',
                    'template_dsp63te',
                    e.target,
                    'user_6741VsGKBwTQwyr86fVHL'
                )
                this.modal.show = false

                this.$fire({
                    title: 'Berhasil!',
                    text: 'Pesan berhasil dikirim',
                    type: 'success',
                    timer: 3000,
                }).then(r => {
                    console.log(r.value)
                })
            } catch (error) {
                console.log({ error })
            }
            // Reset form field
            this.contact.name = ''
            this.contact.email = ''
            this.contact.message = ''
            this.modal.show = false
        },
    },
}
</script>

<style>
#mymap-display img.text-marker {
    max-width: none !important;
    background: none !important;
}
img {
    max-width: none;
}
</style>
